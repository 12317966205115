import ReactLoading from "react-loading";
import styled from "styled-components";

import globalState from "@utils/globalState";

interface IProps {
  withoutBlur?: boolean | undefined;
  posY?: string;
  color?: string;
}

export const Loader = ({ posY, withoutBlur, color }: IProps) => (
  <LoaderContainer posY={posY ?? "20px"} withoutBlur={withoutBlur}>
    <ReactLoading type="spokes" color={color ?? globalState.colorSchema.green} height={25} width={25} />
  </LoaderContainer>
);

const LoaderContainer = styled.section<IProps>`
  display: flex;
  justify-content: center;
  position: absolute;
  top: ${(props) => props.posY};
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: "transparent";
`;
