import React from "react";

import { ReactComponent as SvgDoubleCopy } from "@assets/copy_double.svg";
import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgEdit } from "@assets/edit.svg";
import { ReactComponent as SvgMove } from "@assets/move.svg";
import { ReactComponent as SvgNotTake } from "@assets/not-take.svg";
import { ReactComponent as SvgOwner } from "@assets/owner_grey.svg";
import { ReactComponent as SvgPeople } from "@assets/people.svg";
import { ReactComponent as SvgCreateGroup } from "@assets/people_add_empty.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ReactComponent as SvgToManage } from "@assets/to_manage.svg";
import { Colors } from "@theme/colors";

export type TKeyButtons =
  // | "edit"
  // | "transfer"
  // | "remove"
  // | "did_not_take"
  // | "will_not_take"
  // | "cancel"
  // | "newMeetThisParticipants"
  // | "toManage"
  // | "createGroup"
  // | "toBeOrganizer"
  "copyParticipantsFIO";

interface IValueButtons {
  title: string;
  icon: JSX.Element;
  colorRed?: boolean;
  requiresAuthorRights?: boolean;
  requiresOutlook?: boolean;
}

export const config: Record<TKeyButtons, IValueButtons> = {
  // edit: {
  //   title: "change",
  //   icon: <SvgEdit width={24} height={24} fill={Colors.LIGHT.grey} />,
  //   requiresAuthorRights: true,
  // },
  // transfer: {
  //   title: "meetingTransfer",
  //   icon: <SvgMove width={24} height={24} fill={Colors.LIGHT.grey} />,
  //   requiresAuthorRights: true,
  // },
  // did_not_take: {
  //   title: "tookPlaceNo",
  //   icon: <SvgNotTake width={24} height={24} />,
  //   requiresOutlook: true,
  // },
  // will_not_take: {
  //   title: "takePlaceNo",
  //   icon: <SvgNotTake width={24} height={24} />,
  //   requiresOutlook: true,
  // },
  // cancel: {
  //   title: "cancel",
  //   icon: <SvgRemove width={24} height={12} fill={Colors.LIGHT.red} />,
  //   requiresAuthorRights: true,
  //   colorRed: true,
  // },
  // remove: {
  //   title: "delete",
  //   icon: <SvgDelete />,
  //   requiresAuthorRights: true,
  //   colorRed: true,
  // },
  // newMeetThisParticipants: {
  //   title: "meetSameParticipant",
  //   icon: <SvgPeople />,
  // },
  // toBeOrganizer: {
  //   title: "toBeOrganizer",
  //   icon: <SvgOwner />,
  // },
  // toManage: {
  //   title: "transferControlMeeting",
  //   icon: <SvgToManage />,
  //   requiresAuthorRights: true,
  // },
  // createGroup: {
  //   title: "groupSameParticipant",
  //   icon: <SvgCreateGroup />,
  // },
  copyParticipantsFIO: {
    title: "copyParticipantsNames",
    icon: <SvgDoubleCopy />,
    requiresOutlook: true,
  },
};
