import React from "react";

import { useDimension } from "@hooks/useDimension";
import { TextFont, HeaderModal } from "@ui";

import version from "../../../../../appVersion.json";
import logo from "../../../auth/assets/logo.png";

interface IProps {
  onClose: () => void;
}

export const About = ({ onClose }: IProps) => {
  const { isTablet } = useDimension();

  return (
    <section style={styles.warningContainer}>
      <HeaderModal
        title="about"
        isEdit={false}
        leftSide={{ onPressClose: onClose }}
        styleContainer={isTablet && { backgroundColor: "#ECF0EF" }}
      />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 37 }}>
        <img src={logo} style={{ width: 107, marginBottom: 24, boxSizing: "content-box" }} />
        <>
          <TextFont style={styles.text}>{`Версия приложения ${version.versionName}`}</TextFont>
          <TextFont style={styles.text}>Build Time: 2025-03-03T10:28:41.532</TextFont>
          <TextFont style={styles.text}>Server Version: 1.00.04</TextFont>
        </>
      </div>
    </section>
  );
};

const styles = {
  warningContainer: {
    flex: 1,
  },
  text: {
    color: "#5F6E67",
    fontSize: 18,
    fontWeight: "400",
    lineHeight: "24px",
    "text-align": "center",
  },
};
