import React, { CSSProperties, ReactNode } from "react";

interface IModalButton {
  setIsVisible?: (arg: boolean) => void;
  onHandle?: (() => void) | undefined;
  children: ReactNode;
  style?: CSSProperties;
}

export const NotificationButton = ({ children, onHandle, style }: IModalButton) => (
  <button
    onClick={() => {
      if (onHandle) {
        onHandle();
      }
    }}
    style={{ ...styles.container, ...style }}
  >
    {children}
  </button>
);

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "none",
    padding: "8px",
    marginBottom: "8px",
    width: "100%",
    height: "100%",
  },
};
