import React from "react";

import { LoaderButtonRenderWithCondition } from "@components/hoc/LoaderButtonRenderWithCondition";
import { useSendICSExportMutation } from "@services/userApi";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { Loader, TextFont } from "@ui";

export const ExportICS = () => {
  const [sendICSExport, { isLoading }] = useSendICSExportMutation();

  return (
    <div style={{ ...commonStyles.buttonMain, ...styles.button }}>
      <LoaderButtonRenderWithCondition condition={isLoading} loaderComponent={<Loader color={Colors.LIGHT.white} posY="-10px" />}>
        <button onClick={() => sendICSExport()}>
          <TextFont style={styles.buttonText}>Экспорт ICS файлов</TextFont>
        </button>
      </LoaderButtonRenderWithCondition>
    </div>
  );
};

const styles = {
  button: {
    display: "flex",
    height: 35,
    width: 220,
    justifySelf: "flex-start",
  },
  buttonText: {
    fontSize: 18,
    fontWeight: "700",
    color: Colors.LIGHT.white,
  },
};
